<template>
	<footer class="footer">
		<a
			href="https://www.schaschaweb.de"
			rel="noopener noreferrer"
			target="_blank"
			title="Weiter zu schaschaweb.de"
		>
			&#10094;&#10095; with <span>&#10084;</span> by Schascha
		</a>
	</footer>
</template>

<script>
export default {
	name: 'AppFooter'
};
</script>

<style lang="scss">
	@import '../scss/components/footer';
</style>
